import './App.css';
import { useRef, useEffect } from "react";
import { Player } from '@lottiefiles/react-lottie-player';

import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import landingPageBackgroundAnimation from "./media/videos/landingPageBackground.json";

gsap.registerPlugin(ScrollTrigger);

function App() {

    const landingPageBackgroundPlayer = useRef<Player | null>(null);

    useEffect(() => {
        const backgroundAnimationPathsContainer = landingPageBackgroundPlayer.current?.container?.childNodes[0].childNodes[1] as Element;
        backgroundAnimationPathsContainer.removeAttribute("clip-path");
        (backgroundAnimationPathsContainer as any).style.transform = `scale(0.5) translate(50%, 50%)`;

        document.body.querySelectorAll("path").forEach((path: any) => {
            path.style.stroke = "rgba(155, 155, 155, 0.8)";
            // path.style.strokeWidth = "1px";
            path.style.transition = "all 0.2s";
        })
        for (let i = 0; i < backgroundAnimationPathsContainer.children.length; i++) {
            const gElem = backgroundAnimationPathsContainer.children[i] as Element;
            gElem.addEventListener("click", () => {
                gElem.classList.add("selected");
            });
        }
        animateOnScroll()
    }, [landingPageBackgroundPlayer.current])

    async function animateOnScroll() {
        const timeline = gsap.timeline({
            scrollTrigger: {
                trigger: "#landing-page",
                start: "top top",
                end: "bottom top",
                scrub: true,
            }
        });

        const mainGContainer = landingPageBackgroundPlayer.current?.container?.childNodes[0].childNodes[1] as Element;
        for (let i = 0; i < mainGContainer.children.length; i++) {
            const sectionGContainer = mainGContainer.children[i] as Element;
            for (let sectionChildIndex = 0; sectionChildIndex < sectionGContainer.children.length; sectionChildIndex++) {
                const sectionChild = sectionGContainer.children[sectionChildIndex] as Element;
                // const defaultTransformValue = sectionChild.attributes.getNamedItem("transform")?.value;
                timeline.to(sectionChild, {
                    // transform: defaultTransformValue,
                    yPercent: -300,
                    xPercent: 300,
                    duration: 1,
                    ease: "none",
                }, 0);
            }
        }
    }

    return (
        <div className="App overflow-x-hidden">
            <div id="landing-page" className="flex items-center justify-center w-full h-screen">
                <Player
                    ref={landingPageBackgroundPlayer}
                    autoplay={true}
                    loop={true}
                    keepLastFrame={true}
                    src={landingPageBackgroundAnimation}
                    className="absolute inset-0 w-full h-full text-white"
                ></Player>
                <div className="flex flex-col items-center justify-center w-full">
                    <h1 className="text-header text-center text-3xl md:text-4xl lg:text-7xl">PRIMORDIUM CAPITAL</h1>
                    <p className="text-description text-center text-xs md:text-sm lg:text-base max-w-[500px] lg:max-w-[700px] px-2">We're on a mission to drive the future by investing in cutting-edge crypto projects. Our commitment is to identify and support the most promising innovations within the crypto space, catalyzing progress and creating opportunities for growth.</p>
                </div>
            </div>
            {/* <div className="w-screen h-[2000px]"></div> */}
        </div>
    );
}

export default App;
